@charset "utf-8";

[data-theme="dark"] {
	background-color: var(--color-gray-dark);
	color: var(--color-gray-light);

	a {
		color: var(--color-gray-light);
	}

	a:hover {
		// background-color: var(--color-gray-light);
		color: #0969da;
	}

	p {
		color: var(--color-gray-light);

        &>code {
            font-family: "FiraCode", monospace;
            background-color: #eee;
            color: var(--color-gray-dark);
            font-size: 85%;
            overflow-x: auto;
            border: 1px solid #d0d7de;
            border-radius: 6px;
        }
	}

	blockquote {
		border-left: 5px solid var(--color-gray-light);
		color: var(--color-gray-light);
	}

	pre {
		color: var(--color-gray-dark);

        font-family: "FiraCode", monospace;
        background-color: #eee;
        // letter-spacing: 1px;
        // font-size: 0.9rem;
        font-size: 85%;
        overflow-x: auto;
    
        margin-top: 0.5rem;
        border: 1px solid #d0d7de;
        border-radius: 6px;

        &>code {

        }
	}

	.tags {
		background-color: var(--color-gray-light);
		color: var(--color-gray-dark);
	}

	li {
		color: var(--color-gray-light);

        &>code {
            font-family: "FiraCode", monospace;
            background-color: #eee;
            color: var(--color-gray-dark);
            font-size: 80%;
            overflow-x: auto;
            border: 1px solid #d0d7de;
            border-radius: 1px;
            padding: 2px;
        }
	}

	figcaption {
		color: var(--color-gray-light);
	}

	hr.stylish {
		background-image: linear-gradient(to right, rgba(white, 0.90), rgba(white, 0.45), rgba(white, 0));
	}

	input {
		background-color: var(--color-gray-dark);
		color: var(--color-gray-light);
	}

	input::placeholder {
		color: var(--color-gray-light);
	}

	._progress-wrapper {
		background-color: rgb(51, 51, 51);
	}

	#_progress {
		background: linear-gradient(to right, rgb(204, 204, 204) var(--scroll), transparent 0);
	}

    .title-tags {
        color: var(--color-gray-light);
        font-size: 1.125rem;
    }

    img {
        background-color: #ffffff;
    }
}