@charset "utf-8";

/* Roboto font from Google */
/* Source: https://fonts.google.com/specimen/Roboto */
@font-face
{
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Roboto Regular'), url('/assets/fonts/roboto/roboto-regular.woff2') format('woff2');
}

@font-face
{
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: local('Roboto Italic'), url('/assets/fonts/roboto/roboto-italic.woff2') format('woff2');
}

@font-face
{
	font-family: 'Roboto';
	font-style: normal;
	font-weight: bold;
	font-display: swap;
	src: local('Roboto Bold'), url('/assets/fonts/roboto/roboto-bold.woff2') format('woff2');
}

@font-face
{
	font-family: 'Roboto';
	font-style: italic;
	font-weight: bold;
	font-display: swap;
	src: local('Roboto Bold Italic'), url('/assets/fonts/roboto/roboto-bold-italic.woff2') format('woff2');
}

@font-face
{
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: local('Roboto Light'), url('/assets/fonts/roboto/roboto-light.woff2') format('woff2');
}

/* FiraCode type for code snippets */
@font-face
{
	font-family: 'FiraCode';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('FiraCode Regular'), url('/assets/fonts/firacode-regular.woff2') format('woff2');
}

@font-face
{
	font-family: 'FiraCode';
	font-style: normal;
	font-weight: bold;
	font-display: swap;
	src: local('FiraCode Bold'), url('/assets/fonts/firacode-bold.woff2') format('woff2');
}

:root
{
	--typography-default: 'Roboto', sans-serif;
}
