@charset "utf-8";

table {
	width: 100%;
	border-collapse: collapse;
	border: 1px solid;
	margin-bottom: 20px;

	th,
	td {
		padding: 5px;
		border: 1px solid;
	}
}